@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@layer base {
  body {
    @apply font-pretendard font-normal;
  }

  .font-normal {
    font-weight: 400;
  }

  .font-semibold {
    font-weight: 600;
  }

  .font-bold {
    font-weight: 700;
  }

  /* 스크롤바 숨김 스타일 추가 */
  .hide-scrollbar {
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;  /* IE, Edge */
    scrollbar-width: none;  /* Firefox */
  }
  
  .hide-scrollbar::-webkit-scrollbar {
    display: none;  /* Chrome, Safari */
    width: 0;  /* Chrome, Safari */
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input::placeholder {
  color: #9CA3AF;
}
